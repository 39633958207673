<template>
 <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <nav-header></nav-header>

      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
        <breadcrumb :items="pageData.breadcrumb"></breadcrumb>
        <!-- Chat -->
        <section class="chat mt-sm-5 mt-md-5 pt-md-4 pt-lg-0 mt-lg-0 ">
            <div class="container-fluid h-100">
                <div class="row h-100">
                  <div class="col-12">
                    <div class="my-4 pl-3 py-3 bg-light conversation-note">
                      {{ $t('conversations.note') }}
                      <a target="_blank"
                         :href="'https://old.my.ug.edu.ge/login.php?redirect=/messages.php?lang=geo&token=' + token">{{ $t('conversations.link') }}</a>
                    </div>
                  </div>
                  <conversation-list @conversation-selected="conversationSelected"></conversation-list>

                  <conversation :conversation="selectedConversation"></conversation>
                </div>
            </div>
        </section>

      </main>
      <!-- MAIN CONTENT end -->
    </div>
  </div>
</template>

<script>
  import sidebar from '../components/navbar/sidebar';
  import appHeader from '../components/app-header';
  import pageHeader from '../components/page/page-header';
  import ConversationList from '../components/conversations/conversation-list';
  import Conversation from '../components/conversations/conversation';
  import { mapGetters } from 'vuex';
  import breadcrumb from '../components/page/breadcrumb';
  import sidebarSm from '../components/navbar/sidebar-sm';
  import navHeader from '../components/navbar/nav-header';
  import pageTitle from '../components/page/page-title';

  export default {
    name: 'conversations',
    components: { ConversationList, Conversation, sidebar, appHeader, pageHeader, breadcrumb, sidebarSm, navHeader, pageTitle },

    computed: {
      ...mapGetters({
        currentUser: 'auth/user',
        token: 'auth/token'
      }),

      pageData() {
        return {
          title: this.$t('conversations.title'),
          breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
          },
            {
              icon: '',
              title: this.$t('conversations.title'),
              link: '/conversations',
              isActive: true,
            }],
        };
      },
    },

    data() {
      return {
        selectedConversation: null
      }
    },

     

    methods: {
     

      conversationSelected(conversation) {
        this.selectedConversation = conversation;
      }
    }
  };
</script>

<style scoped>
  .conversation-note {
    border-radius: 20px;
  }
</style>
