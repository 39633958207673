<template>
<!--  <div :class="['media', { new: isNew }, { selected: isSelected }]">-->
<!--    <div class="main-img-user">-->
<!--      <img alt="" :src="pictureUrl" class="avatar avatar-md brround">-->
<!--      <span v-if="isNew">{{ newMessagesCount }}</span>-->
<!--    </div>-->
<!--    <div class="media-body">-->
<!--      <div class="media-contact-name">-->
<!--        <span v-html="fullName"></span>-->
<!--        <span v-if="lastMessageDate">{{ lastMessageDate }}</span>-->
<!--      </div>-->
<!--      <p v-if="lastMessage">{{ lastMessage }}</p>-->
<!--    </div>-->
<!--  </div>-->
    <div class="msg__item-wrapper">
      <div class="msg__card">
        <div class="msg__info">
           <!--
              Avatar:
                      student -> (add student-ic .class) msg__avatar -> span
                      lecturer ->  (add lecturer-ic .class)
                      group ->  (add group-ic .class)
          -->
          <!--
              unique value in list:
                      [data-target]
                      [aria-controls]
                      [id] of msg__tree
          -->
          <div class="msg__avatar">
             <span :class="iconClass"></span>
            <!-- <img :src="pictureUrl" alt="student message" class="img-fluid"> -->
          </div>
          <div>
            <span class="sender" v-html="fullName"></span>
            <div class="sender" style="font-size: 11px" v-html="position"></div>
            <p v-if="lastMessage" class="sent-text">{{ lastMessage.text }}</p>
          </div>
        </div>
        <div class="msg__info-details">
            <!--<div class="msg__conversation-show">
                <button type="button" data-toggle="collapse" data-target="#conversation-tree-3" aria-expanded="false" aria-controls="conversation-tree-3" aria-label="Expand conversation" tabindex="-1" class="b-9">
                    <span class="toggle-messages"></span>
                </button>
            </div>-->
            <div class="msg__info-details-more" v-if="isNew">
              <div class="messages-count">
                <div><span>{{ newMessagesCount }}</span></div>
              </div>
            </div>

        </div>

      </div>
    </div>
</template>

<script>
  export default {
    name: 'conversation-list-item',

    props: ['is-new', 'is-selected', 'is-student', 'full-name', 'position', 'last-message-date', 'last-message', 'new-messages-count'],

    computed: {
      iconClass() {
        if(this.isStudent == 1) return 'student-ic';

        return 'lecturer-ic';
      }
    },

    methods: {

    }
  };
</script>

<style scoped>

</style>
