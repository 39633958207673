<template>
  <div class="msg__item-wrapper">
    <div class="msg__card">
      <div class="msg__info">
         <!-- 
              Avatar:
                      student -> (add student-ic .class) msg__avatar -> span
                      lecturer ->  (add lecturer-ic .class)
                      group ->  (add group-ic .class)
          -->
          <!-- 
              unique value in list:
                      [data-target]
                      [aria-controls]
                      [id] of msg__tree   
          -->
        <div class="msg__avatar">
          <span class="student-ic"></span>
        </div>
        <div>
          <span class="sender" v-html="fullName"></span>
          <p class="sent-text" v-if="lastMessage">{{ lastMessage }}</p>
        </div>
      </div>
      <div class="msg__info-details">
        <div class="msg__conversation-show">
          <button type="button" data-toggle="collapse" :data-target="'#conversation-tree-' + idx" aria-expanded="false" :aria-controls="'conversation-tree-' + idx" aria-label="Expand conversation" tabindex="-1" class="b-9">
            <span class="toggle-messages"></span>
          </button>
        </div>
        <div class="msg__info-details-more" v-if="isNew">
          <span class="date">{{ lastMessageDate }}</span>
          <div class="messages-count">
            <div><span>{{ newMessagesCount }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="msg__tree collapse" :id="'conversation-tree-' + idx" data-parent="#message__list">
      <div class="msg__tree-wrapper">
        <div><span class="sender-inner">ნათია მანჯიკაშვილი</span></div>
        <div><span class="sender-inner">ნათია მანჯიკაშვილი</span></div>
        <div><span class="sender-inner">ნათია მანჯიკაშვილი</span></div>
        <div><span class="sender-inner">ნათია მანჯიკაშვილი</span></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'conversation-list-group-item',

    props: ['idx', 'is-new', 'is-selected', 'full-name', 'last-message-date', 'last-message', 'new-messages-count'],

    methods: {

    }
  };
</script>

<style scoped>

</style>
