<template>
  <li :class="messageFeedClass">
    <div class="mb-4">
      <p class="name">{{ helpers.getLocalizedField(message, 'full_name') }}</p>
      <span class="date-time">{{ dateTime }}</span>
    </div>
    <div class="message" v-html="message.text"></div>
    <div class="attached-files mt-3" v-if="message.files.length > 0">
      <span class="text-black font-weight-bold">{{ $t('conversations.attached-files') }}</span>
      <div v-for="file of message.files" class="file">
        <a :href="file.file_url + '?token=' + token">{{ ' '+file.file_name }}</a>
      </div>
    </div>
  </li>
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';

  export default {
    name: 'conversation-item',

    props: ['message'],

    computed: {
      ...mapGetters({
        currentUser: 'auth/user',
        token: 'auth/token'
      }),

      dateTime() {
        return moment(this.message.created_at).format('YYYY-MM-DD HH:mm:ss');
      },

      sentByCurrentUser() {
        return this.message.user_code === this.currentUser.code;
      },

      messageFeedClass() {
        return this.message.user_code === this.currentUser.code ? 'outgoing__msg' : 'incomming__msg';
      },

    }
  };
</script>

<style scoped>
  .attached-files {
    direction: ltr;
  }
</style>
