<template>

  <!--
     MESSAGE LIST
  -->
  <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-3  h-100 px-sm-2 px-md-3 px-lg-3">
    <div class="b-20">
      <!-- Search Message -->
      <div class="message-search-area">
        <form class="tabs-menu1 w-100" @submit.prevent="loadList">
          <div class="input-group">
            <input v-model="keyword" type="text" class="form-control px-5 " :placeholder="$t('conversations.search')">
            <img src="/static/images/icons/chat/search.png" class="img-fluid ">
          </div>
        </form>
      </div>
      <div class="chat-bg">
        <!-- Messages List -->
        <div class="outer">
          <div class="message__list-wrapper">
            <ul class="message__list" id="message__list">
              <li v-if="!conversationList.data || conversationList.data.length === 0" class="text-center pt-4">{{ $t('conversations.not-found') }}</li>
              <!--
                  Avatar:
                          student -> student.png
                          lecturer -> lecturer.png
                          group -> group.png   (add group-ic .class)
              -->
              <!--
                  unique value in list:
                          [data-target]
                          [aria-controls]
                          [id] of msg__tree
              -->
  <!--            <infinite-loading v-if="conversationList.data.length > 0" @infinite="loadNextPage">-->
  <!--              <span slot="no-more"></span>-->
  <!--            </infinite-loading>-->
              <li :class="['msg__item', { active: isConversationSelected(conversation)}]"
                  :key="$index"
                  @click="selectConversation(conversation)"
                  v-for="(conversation, $index) of conversationList.data">
                <conversation-list-group-item
                  v-if="conversation.is_group"
                  :idx="$index"
                  :full-name="getConversationUser(conversation)"
                  :is-new="isConversationNew(conversation)"
                  :is-selected="isConversationSelected(conversation)"
                  :last-message="getConversationLastMessage(conversation)"
                  :last-message-date="getConversationLastMessageDate(conversation)"
                  :new-messages-count="getNewMessagesCount(conversation)"
                ></conversation-list-group-item>
                <conversation-list-item
                  v-else
                  :idx="$index"
                  :is-student="parseInt(conversation.is_student)"
                  :full-name="getConversationUser(conversation)"
                  :position="getConversationPosition(conversation)"
                  :is-new="isConversationNew(conversation)"
                  :is-selected="isConversationSelected(conversation)"
                  :last-message="getConversationLastMessage(conversation)"
                  :last-message-date="getConversationLastMessageDate(conversation)"
                  :new-messages-count="getNewMessagesCount(conversation)"
                ></conversation-list-item>

              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ConversationListItem from './conversation-list-item';
  import ConversationListGroupItem from './conversation-list-group-item';
  import  { mapGetters } from 'vuex';
  import SkList from '../skeletons/sk-list';
  import helpers from '../../helpers';
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'conversation-list',

    components: { SkList, ConversationListItem, ConversationListGroupItem, InfiniteLoading },

    computed: {
      ...mapGetters({
        conversationList: 'conversation/conversationList'
      })
    },

    data() {
      return {
        keyword: '',
        selectedConversation: null,
        page: 1,
      }
    },
    mounted() {
      this.loadList();
    },

    methods: {

      selectConversation(conversation) {
        this.selectedConversation = conversation;
        this.$emit('conversation-selected', conversation);
      },
      isConversationNew(conversation) {
        return this.getNewMessagesCount(conversation) > 0;
      },

      isConversationSelected(conversation) {
        return this.selectedConversation === conversation;
      },

      getConversationLastMessage(conversation) {
        return conversation.last_message;
      },

      getConversationLastMessageDate(conversation) {
        return conversation.last_message_date;
      },

      getNewMessagesCount(conversation) {
        return conversation.new_messages_count;
      },

      getConversationUser(conversation) {
        let name = conversation.name;

        if(conversation.fname && conversation.lname) {
          name = helpers.getLocalizedField(conversation, 'fname') + ' ' + helpers.getLocalizedField(conversation, 'lname');
        }

        return helpers.highlightString(name, this.keyword, 'link-red');
      },

      getConversationPosition(conversation) {
        return helpers.getLocalizedField(conversation, 'departments');
      },

      loadList() {
        this.page = 1;
        this.$store.dispatch('conversation/loadConversationList', {
          keyword: this.keyword,
          page: this.page
        });
      },

      loadNextPage($state) {
        this.$store.dispatch('conversation/loadConversationList', {
          keyword: this.keyword,
          page: this.page
        })
        .then((response) => {
          this.page++;
          if(response.data.data.length > 0) $state.loaded();
          else $state.complete();
        })
      }
    },
  };
</script>

<style scoped>
  .main-chat-list {
    overflow-y: scroll;
  }

  .chat .message__list {
    height: 100%;
    background-color: #fff;
  }
  @media (max-width: 1800px){

    .chat .input-group img {
      z-index: 10000;
    }
    .chat .input-group input:focus{
      padding-left: 4.2rem !important;
      background-color: transparent;
      color: #fff;
    }
  }
</style>
