<template>
  <!--
      CONVERSATION
  -->
  <div class="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-9 px-sm-2 px-sm-3 px-md-1 px-lg-1 conversation-sm-h">
       <div class="b-20">
            <!-- Conversation Header -->
            <div class="conversation-header d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center justify-content-start">
                    <div class="mr-2 pr-lg-2 pr-md-1 pl-md-3  pl-lg-0 ml-md-4 ml-lg-5 ">
                        <img :src="picture" alt="group header" class="img-fluid"  v-if="conversation" style="max-height:4rem;">
                    </div>
                    <div>
                        <span class="heading">{{ name }}</span>
                    </div>
                </div>
                <!-- close button visible only on xs (mobile) -->
                <div class="d-block d-sm-none">
                    <span class="close-button"></span>
                </div>
            </div>

            <!-- Conversation Body -->
            <div class="chat-bg bg-light">
              <div class="d-flex flex-column align-items-end justify-content-center w-100 h-98 position-relative">
                <!-- conversation messages -->
                  <div class="conversation__wrapper">
                    <div class="conversation__wrapper-inner" v-if="conversation">
      <!--            <infinite-loading v-if="messages.length > 0" direction="top" @infinite="loadNextMessages">-->
      <!--              <span slot="no-more"></span>-->
      <!--            </infinite-loading>-->
                      <ul>
                        <conversation-item :key="i" :message="message" v-for="(message, i) of messages"></conversation-item>
                      </ul>
                    </div>
                    <div v-else class="text-center pt-4">
<!--                      {{ $t('conversations.please-select-conversation') }}-->
                    </div>
                  </div>
                  <!-- form -->
                  <div class="conversation__start">
                      <div class="conversation__start-inner">
                          <div class="d-flex align-items-center justify-content-between">
                              <div class="form-group flex-grow-1  pr-5 pr-sm-3 mr-lg-4">
                                <div v-if="attachments.length > 0" class="row mb-4" >
                                  <div class="col-12 mb-2 text-right" v-for="(attachment, $index) of attachments">
                                    {{ attachment.name  }}

                                    <b-button
                                      @click="deleteAttachment($index)"
                                      variant="danger"
                                      class="btn btn-primary-red-7 d-inline ml-3">
                                      {{ $t('conversations.delete-file') }}
                                    </b-button>
                                  </div>
                                </div>
                                  <textarea :disabled="!conversation"
                                            @keypress.enter="($event) => { $event.preventDefault(); sendMessage() }"
                                            v-model="messageText"
                                            :placeholder="$t('conversations.enter-text')"
                                            class="form-control" ></textarea>
                              </div>
                              <div>
                                  <button :disabled="!conversation" @click="sendMessage" class="btn btn-round btn-round-send mb-3 mb-sm-4 d-block ">
                                    <img src="/static/images/icons/chat/send.png" class="img-fluid">
                                  </button>
                                  <button :disabled="!conversation" @click="handleChooseFileBtnClick" class="btn btn-round btn-round-attachment d-block">
                                    <img src="/static/images/icons/chat/attach.png" class="img-fluid">
                                  </button>
                                <input @change="handleChooseFile"
                                       multiple
                                       type="file"
                                       id="attachment"
                                       ref="attachment"
                                       class="d-none">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        </div>
  </div>
</template>

<script>
  import ConversationItem from './conversation-item';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import InfiniteLoading from 'vue-infinite-loading';

  const allowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg', 'png', 'ppt', 'pptx'];

  export default {
    name: 'conversation',

    props: ['conversation'],

    components: { ConversationItem, InfiniteLoading },

    computed: {
      ...mapGetters({
        messages: 'conversation/conversationMessages',
        picture: 'conversation/conversationPicture',
        name: 'conversation/conversationName',
        currentUser: 'auth/user',
      })
    },

    data() {
      return {
        messageText: '',
        page: 1,
        attachments: []
      }
    },

    watch: {
      conversation(newVal, oldVal) {
        this.conversationSelected();
      }
    },

    methods: {
      deleteAttachment(i) {
        this.attachments.splice(i, 1);
      },

      handleChooseFile($event) {
        const files = $event.target.files;

        if(files.length > 0) {
          for(const file of files) {
            const ext = file.name.split('.').pop();

            if(allowedExtensions.indexOf(ext) !== -1) this.attachments.push(file);
            else {
              this.helpers.notifyErrorMessage(this.$t('conversations.file-error', {types: allowedExtensions.join(', ')}));
            }
          }
        } else this.attachments = [];
      },

      handleChooseFileBtnClick() {
        document.getElementById('attachment').click();
      },

      loadNextMessages($state) {
        this.page++;
        this.$store.dispatch('conversation/loadConversationMessages', {
          page: this.page
        })
          .then((response) => {
            if(response.data.data.length > 0) $state.loaded();
            else $state.complete();
          });
      },

      conversationSelected() {

        
        this.page = 1;

        this.$store.dispatch('conversation/conversationSelected', this.conversation)
        .then(() => {
          this.helpers.scrollChatWindowBottom();
          this.setSeen();
        });
      },

      setSeen() {
        this.conversation.newMessages = [];
        this.conversation.new_messages_count = 0;
        const lastMessage = this.messages[this.messages.length - 1];

        if(lastMessage && lastMessage.seenBy.indexOf(this.currentUser.code) === -1) {
          this.$emit('message-seen', lastMessage);
          this.$store.dispatch('conversation/setSeen', {
            message: lastMessage
          })
        }
      },

      sendMessage() {
        this.$store.dispatch('conversation/sendMessage', {
          message: this.messageText,
          attachments: this.attachments
        })
        .then(response => {

           

          this.messageText = '';
          this.attachments = [];
          this.$store.dispatch('conversation/loadConversationMessages',{conversationID:response.data.data.conversationID});

          this.helpers.scrollChatWindowBottom();
        });
        this.$emit('message-sent');
      },

      getUserAvatar(message) {
        // TODO
        return 'https://laravel.spruko.com/dashtic/Leftmenu-Icon-LightSidebar-ltr/assets/images/users/16.jpg';
      },

      getMessageDate(message) {
        return moment(message.created_at).format('DD-MM-YYYY HH:mm:ss');
      }
    }
  };
</script>

<style scoped>
  .empty-ms-body {
    min-height: 80vh;
  }
  #ChatBody {
    overflow-y: scroll;
  }
</style>
