import { render, staticRenderFns } from "./conversation-list-item.vue?vue&type=template&id=21f23fa4&scoped=true"
import script from "./conversation-list-item.vue?vue&type=script&lang=js"
export * from "./conversation-list-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f23fa4",
  null
  
)

export default component.exports